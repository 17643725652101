import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientFormVersionHistory from '../../models/ClientFormVersionHistory';
import ClientFormService from '../../services/ClientFormService';
import Loader from '../shared/Loader';
import { ClientFormStatus } from '../../models/ClientFormStatus';
import VersionTimeline from '../shared/version-timeline/VersionTimeline';
import { EventSystem } from '../../events/EventSystem';

type GroupedHistory = {
  parent: ClientFormVersionHistory;
  children: ClientFormVersionHistory[];
};

type FormVersionHistoryProps = {
  clientForm: { id: string; minorVersion: number; majorVersion: number; status: ClientFormStatus; originId: string | null };
  minimal?: boolean;
};

const FormVersionHistory: FC<FormVersionHistoryProps> = (props) => {
  const { clientForm, minimal } = props;
  const [loading, setLoading] = useState(true);
  const [versionHistories, setVersionHistories] = useState<ClientFormVersionHistory[]>([]);
  const { t } = useTranslation('form');

  useEffect(() => {
    setLoading(true);
    ClientFormService.getUserHistories(clientForm.originId || clientForm.id).then((res) => {
      const versions = res.data.sort((a, b) => new Date(b.createdUtc).getTime() - new Date(a.createdUtc).getTime());
      setVersionHistories(versions);
      setLoading(false);

      EventSystem.fireEvent('user-state-histories-loaded', {
        clientFormId: clientForm.id,
        versions: versions.filter((x) => x.majorVersion === clientForm.majorVersion && x.minorVersion === clientForm.minorVersion),
      });
    });
  }, [clientForm.id, clientForm.minorVersion, clientForm.majorVersion, clientForm.status, clientForm.originId]);

  const groupedVersionHistory = useMemo<GroupedHistory[]>(() => {
    const result: GroupedHistory[] = [];

    // We group entries together if they are the same version number,
    // but each different version gets its own group
    let key = 0;
    for (const versionHistory of versionHistories.sort((a, b) => new Date(a.createdUtc).getTime() - new Date(b.createdUtc).getTime())) {
      if (result[key]?.parent.majorVersion === versionHistory.majorVersion) {
        result[key].children.unshift(result[key].parent);
        result[key].parent = versionHistory;
        continue;
      }

      key++;
      if (!result[key]) {
        result[key] = {
          parent: versionHistory,
          children: [],
        };
      } else {
        result[key].children.push(versionHistory);
      }
    }

    result.reverse();
    return result;
  }, [versionHistories]);

  return (
    <div className={`${minimal ? 'mt-4' : ''}`}>
      {minimal && <div className="sticky top-0 my-4 border-b bg-white px-4 pb-2 font-medium">{t('left-tabs.details.headings.version-history')}</div>}

      {loading && (
        <div className="relative pt-16">
          <Loader size={12} />
        </div>
      )}
      <div
        className={`overflow-y-auto px-4 ${minimal ? '' : 'max-h-[400px] min-h-96 '} ${
          groupedVersionHistory.length === 0 ? 'py-2' : 'border-b-0'
        } border-gray-3`}
      >
        {!loading && <VersionTimeline items={groupedVersionHistory} activeVersion={clientForm.majorVersion} originId={clientForm.originId} />}
        {!loading && groupedVersionHistory.length === 0 && <div className="text-center">{t('info-modal.history.none')}</div>}
      </div>
    </div>
  );
};

export default FormVersionHistory;
